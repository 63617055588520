import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filterstring',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], field : string, value : string): any[] {  
      if (!items) return [];
      return items.filter(it => 
      (!value || (it[field] && value && it[field].toLowerCase().indexOf(value.toLowerCase()) >= 0 ) ) );
    }
}