import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filterboolean',
    pure: false
})
export class FilterBooleanPipe implements PipeTransform {
    transform(items: any[], field : string, value : boolean): any[] {  
      if (!items) return [];
      return items.filter(it => 
      (!value && !it[field]) || (it[field] && it[field] == value) );
    }
}