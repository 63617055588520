const emailList = [
    {
        icon: 'mdi mdi-inbox',
        name: 'Inbox',
        value: 8,
        text: 'danger'
    },
    {
        icon: 'mdi mdi-star',
        name: 'Starred'
    },
    {
        icon: 'mdi mdi-file-document-box',
        name: 'Draft',
        value: 32,
        text: 'info'
    },
    {
        icon: 'mdi mdi-send ',
        name: 'Sent Mail'
    },
    {
        icon: 'mdi mdi-delete',
        name: 'Trash'
    }
];

const emailLabel = [
    {
        text: 'info',
        name: 'Web App'
    },
    {
        text: 'warning',
        name: 'Recharge'
    },
    {
        text: 'dark',
        name: 'Wallet Balance'
    },
    {
        text: 'primary',
        name: 'Friends'
    },
    {
        text: 'success',
        name: 'Family'
    }
];

export { emailList, emailLabel };
